<script setup lang="ts">
const { t } = useT();
const { open } = useFunrizeModals();

const { data, activeCard, durationLeft } = useScratchCard();

const btnText = computed(() => {
	if (activeCard.value?.status === "ready_for_activate") {
		return t("Claim");
	}
	return t("Get a ticket");
});

const goldenCardData = computed(() =>
	data.value?.cards?.find((card) => card?.slug === "gold" && card?.status === "completed")
);

const handleCardClick = () => {
	if (goldenCardData.value) {
		return;
	}
	if (activeCard.value?.status === "ready_for_activate") {
		open("LazyOModalScratchCardsTicketPlay", { cardType: activeCard.value?.slug });
		return;
	}
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};

const { durationLeft: goldenCardTimeLeft, reset: resetGoldenCardTimeLeft } = useCountdown({
	timestamp: goldenCardData.value?.timer as string,
	formatToken: "HH[:]mm[:]ss"
});

watch(
	() => goldenCardData.value?.timer,
	() => {
		resetGoldenCardTimeLeft(goldenCardData.value?.timer ?? "");
	}
);
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 328,
			height: 257,
			src: '/nuxt-img/scratch-cards/promo-cards-bg.webp',
			alt: 'prize-logo',
			format: 'avif',
			loading: 'lazy'
		}"
		height="257px"
		:class="{ 'played-today': goldenCardData }"
		@click="handleCardClick"
	>
		<template #top>
			<div class="block">
				<AText variant="ternopil" :modifiers="['normal', 'uppercase']" class="title">
					{{ t("Scratch Mania") }}
				</AText>
				<AText variant="topeka" :modifiers="['bold', 'uppercase']" class="sub-title">
					{{ t("Unlock prize tickets with your purchases") }}
				</AText>
				<AText v-if="!goldenCardData" variant="topeka" as="div" class="scratch-timer">
					{{ t("Promotion ends in") }} <b>{{ durationLeft }}</b>
				</AText>
			</div>
		</template>
		<template #bottom>
			<div class="bottom-wrapper">
				<MScratchCardsProgressBar
					v-if="!goldenCardData"
					:value="activeCard?.progress?.pointsComplete ?? 0"
					:maxValue="activeCard?.progress?.points ?? 0"
				/>
				<AButton variant="primary" :disabled="!!goldenCardData" class="promo" data-tid="promo-scratch-card">
					<AText v-if="!goldenCardData" variant="toledo" :modifiers="['semibold', 'uppercase']">
						{{ btnText }}
					</AText>
					<AText v-else variant="toledo" :modifiers="['semibold', 'uppercase']">
						<i18n-t keypath="Available in: {timer}">
							<template #timer>
								{{ goldenCardTimeLeft }}
							</template>
						</i18n-t>
					</AText>
				</AButton>
			</div>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card {
	&::before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		position: absolute;
		z-index: 0;
		background: var(--ganganagar);
	}

	&:deep(.top),
	&:deep(.bottom) {
		align-items: flex-start;
	}

	&:deep(.bottom) {
		padding-bottom: 28px;
	}

	&.played-today {
		cursor: not-allowed;

		&:deep(.top) {
			justify-content: flex-end;
		}

		&:deep(.bottom) {
			justify-content: flex-start;
		}

		.title {
			position: static;
			display: inline-block;
			margin-bottom: 4px;
		}
	}

	.title {
		position: absolute;
		top: 42px;
		left: 20px;
		max-width: 100%;
	}

	.sub-title {
		display: block;
		min-height: 15px;
		max-width: 205px;
		margin-bottom: 20px;
	}
}

.bottom-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 240px;
	max-width: 100%;
}

button {
	height: 40px;
	gap: 6px;

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}
</style>
